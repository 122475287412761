(function() {
	var scrollBtn = document.querySelector('.back-to-top') ? document.querySelector('.back-to-top') : "",
	scrollTimeOut = function(){
		setTimeout(function(){
			scrollBtn.classList.add('hide');
		}, 200);
	},
	goToTop = function(e){
		e.preventDefault();
		window.scrollTo({
			top: 0,
                	behavior: "smooth"
            	});
            	
            	// set focus to the top of the page (4i logo?) so keyboard user pressing tab won't still be stuck at the bottom of the page
            	if(document.querySelector(".HeaderLogo")){
            		setTimeout(function(){ 
            			document.querySelector(".HeaderLogo").getElementsByTagName("a")[0].focus();
            		}, 500);
            	}else if(document.querySelector(".logo2017")){
            		setTimeout(function(){ 
            			document.querySelector(".logo2017").getElementsByTagName("a")[0].focus();
            		}, 500);
            	}
            	
		return false;
	},
	initiateScroll = function(){
		if (window.pageYOffset > 300) {
			scrollBtn.classList.remove('hide');
			scrollBtn.style.opacity="1";
			clearTimeout(scrollTimeOut);
		} else {
			scrollBtn.style.opacity="0";
			scrollTimeOut();
		}
	};
	if(scrollBtn != "") {
		window.removeEventListener("scroll", initiateScroll);  //can remove this once bundles are published
		window.addEventListener("scroll", initiateScroll);
		
		scrollBtn.removeEventListener("click", goToTop); //can remove this once bundles are published
		scrollBtn.addEventListener("click", goToTop);
	}
})();